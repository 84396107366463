import CbError from "@/utils/cb-error"
import TraceKit from "tracekit"
import LogDumper from "@/models/log-dumper"
import Vue from 'vue'
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue'
import { uuid } from "@/utils/cbjs-helper";

class LogData {
  constructor() {
    this.version = 1;
    this.server_version = 1;
    this.meta = {};
    this.data = {};
    this.id = uuid();
    this.logDumper = new LogDumper(this, 5000);
  }

  payload() {
    var pl = {type: 'info', info: this.data, id: this.id, meta: this.meta};
    // reset data
    this.data = {};
    return pl;
  }

  send(type) {
    var pl = {type: type, data: this.data, id: this.id, meta: this.meta};
    this.data = {};
    this.meta = {};
    this.logDumper.sendPayLoad(pl);
  }

  sendError(error) {
    var pl = {error: error, type: 'error', id: this.id, meta: this.meta};
    this.logDumper.sendPayLoad(pl);
  }

  append(key, value) {
    this.data[key] = this.data[key] || [];
    this.data[key].push(value);
    this.version += 1;
  }

  add(log) {
    Object.assign(this.data, log);
    this.version += 1;
  }
}


class Logger {
  static get DEBUG() {
    return 0;
  }

  static get INFO() {
    return 1;
  }

  static get WARN() {
    return 2;
  }

  static get ERROR() {
    return 3;
  }

  constructor(mode, consoleLogEnabled, sentry) {
    this.mode = mode;
    this.consoleLogEnabled = consoleLogEnabled;
    this.logData = new LogData();
    if(sentry) {
      this.sentry = sentry;
      Raven.config(this.sentry.dsn, this.sentry.options)
          .addPlugin(RavenVue, Vue)
          .install();
      Raven.setTagsContext(this.sentry.tags);
    }
  }

  // hosted page id
  addMetaData(key, value) {
    this.logData.meta[key] = value;
  }


  d(log) {
    if(this.mode <= Logger.DEBUG) {
      this._l(log)
    }
  }

  i(log) {
    if(this.mode <= Logger.INFO) {
      this._l(log)
    }
  }

  a(key, value) {
    if(this.mode <= Logger.INFO) {
      this._a(key, value)
    }
  }

  w(log) {
    if(this.mode <= Logger.WARN) {
      this._l(log)
    }
  }

  e(error) {
    if(this.mode <= Logger.ERROR) {
      this._e(error);
    }
  }

  _l(log) {
    if(this.consoleLogEnabled) {
      // console.log(Date.now(), log);
    }

    if(typeof log === 'object') {
      this.logData.add(log);
    }
  }

  _a(key, value) {
    this.logData.append(key, value);
  }

  _e(error) {
    // dont send if the following conditions are met 
    // error is instance of cberror(Meaning server send error)
    // error type is internal (form errors);
    if(!((error instanceof CbError && !error.notifyBackend) || error.type == "internal")){
      // trackjs
      // server send
      try {
        // if the error is not an error object, tracekit is rethrowing error
        TraceKit.report(error);
      }
      catch(error) {

      }
      if(this.sentry) {
        Raven.captureException(error);
      }
    }

    if(process.env.NODE_ENV != "production") {
      throw error;
    }
  }

  kvl(data) {
    if(data && typeof data == "object" && Object.keys(data).length > 0) {
      this.i(data);
      this.logData.send('kvl');
    }
  }
}

TraceKit.remoteFetching = false;
TraceKit.report.subscribe((errorReport) => {
  // if(logger.sentry) {
  //   Raven.captureMessage(JSON.stringify(errorReport));
  // }
  logger.logData.sendError(errorReport);
});


export default Logger