import MultiDecimalHelper from "@/utils/multi-decimal"
import subCancelReasonCodeConfig from "@/utils/sub-cancel-reason-code-config"
export default class PortalHelper {
  static constructOrderItemFromSubData(subData) {
    let output = JSON.parse(JSON.stringify(subData.orderItem));
    return output;
  }

  static cloneAddons(addons) {
    return JSON.parse(JSON.stringify(addons));
  }

  static getDetailsFromCart(cart) {
    return {
      planDetail: cart.orderItems[0].plan.details,
      addonDetails: cart.orderItems[0].addons.map(({details}) => details)
    }
  }

  static displayPeriod(days, trial_unit) {
    return `${days} day${days > 1 ? 's' : ''}`;
  }

  static enableChangeSub(changedOrderItem, oldSubData) {
    var oldOrderItem = oldSubData.orderItem
    var oldItems = [].concat(oldOrderItem.plan, oldOrderItem.addons);

    // Compare addon IDs from existing sub (if any ) with the new addon IDs added to the cart
    
    const oldAddons = JSON.stringify(oldOrderItem.addons.map(addons => addons.id).sort());
    const newAddons = JSON.stringify(changedOrderItem.addons.map(addons => addons.id).sort());

      if(oldAddons !== newAddons){
        return true;
      }

    return oldItems.filter(item => {
      if(item.type == "plan") {
        return (item.id != changedOrderItem.plan.id) 
        || ((item.quantity_in_decimal != changedOrderItem.plan.quantity_in_decimal)
        || ((item.quantity || 1) != (changedOrderItem.plan.quantity || 1)));
      }
      if(item.type == "addon") {
        let t = changedOrderItem.addons.filter(i => i.id == item.id)[0];
        return (t && (t.quantity_in_decimal != item.quantity_in_decimal)) || (t && (t.quantity != item.quantity));
      }
    }).length > 0 || !!PortalHelper.hasCouponChanges(changedOrderItem.coupon_list , oldSubData.recurringEstimate && oldSubData.recurringEstimate.discounts);
  }

  static hasCouponChanges(newCouponList, oldDiscount) {
    return (newCouponList && oldDiscount) ? newCouponList.length != oldDiscount.length
              || newCouponList.filter(coupon => !oldDiscount.some(discount => discount.entity_id == coupon.id)).length > 0
              || oldDiscount.filter(discount => !newCouponList.some(coupon => coupon.id == discount.entity_id)).length > 0
          : (newCouponList && newCouponList.length > 0 && !oldDiscount) || (!newCouponList && oldDiscount && oldDiscount.length > 0);
  }

  static filterAddonsBasedOnPlanTerm(addons, plan, includeNonRecAddons) {
    var selected_addons = []
    switch(plan.period_unit) {
      case "week":
        selected_addons = addons.filter(a => a.period_unit == "week" && (plan.period % a.period) == 0);
        selected_addons = selected_addons.concat(addons.filter(a => a.period_unit == "day" && ((plan.period * 7) % a.period) == 0))
        break;
      case "month":
        selected_addons = addons.filter(a => a.period_unit == "month" && (plan.period % a.period) == 0);
        break;
      case "year":
        selected_addons = addons.filter(a => a.period_unit == "year" && (plan.period % a.period) == 0);
        selected_addons = selected_addons.concat(addons.filter(a => a.period_unit == "month" && ((plan.period * 12) % a.period) == 0))
        break;
      case "day":
        selected_addons = addons.filter(a => a.period_unit == "day" && (plan.period % a.period) == 0);
        break;
    }

    // Non-recurring addons
    if(includeNonRecAddons) { 
      selected_addons = selected_addons.concat(addons.filter(a => {
        return (!a.period && a.period_unit == "not_applicable") || a.item_type == 'charge'
      }))
    }
    return selected_addons;
  }

  static constructOrderItemForOtherProducts(other_product, subData, getters) {
    var current_addons = this.cloneAddons(subData.orderItem.addons);
    var orderItem = {
      plan: {
        id: other_product.plan_id,
        quantity: other_product.plan_id == subData.orderItem.plan.id ? subData.orderItem.plan.quantity : 1,
        type: "plan"
      }
    }
    if(subData.isMultiDecimal) {
      orderItem.plan.quantity_in_decimal = other_product.plan_id == subData.orderItem.plan.id ? subData.orderItem.plan.quantity_in_decimal : MultiDecimalHelper.getDefaultQuantity();
    }
    orderItem.plan.initialQuantity = MultiDecimalHelper.getInitialQuantity(orderItem.plan);

    if(other_product.plan_id == subData.orderItem.plan.id)  {
      orderItem.addons = [].concat(current_addons);
    } else {
      let addonsList = current_addons;
      if(other_product.is_addons_restricted) {
        addonsList = addonsList.filter(addon => !!other_product.allowed_addons.find(_addon => _addon.id === addon.id))
      }
      var selected_addons = [];
      switch(subData.retain_addons_for_change && other_product.period_unit) {
        case "week":
          var selected_addons = addonsList.filter(a => a.details.period_unit == "week" && (other_product.period % a.details.period) == 0);
          break;
        case "month":
          var selected_addons = addonsList.filter(a => a.details.period_unit == "month" && (other_product.period % a.details.period) == 0);
          break;
        case "year":
          var selected_addons = addonsList.filter(a => a.details.period_unit == "year" && (other_product.period % a.details.period) == 0);
          selected_addons = selected_addons.concat(addonsList.filter(a => a.details.period_unit == "month" && ((other_product.period * 12) % a.details.period) == 0))
          break;
        case "day":
          var selected_addons =  addonsList.filter(a => a.details.period_unit == "day" && (other_product.period % a.details.period) == 0);
          break;
      }

      // union of other_product.addons and selected_addons
      let o = [].concat(selected_addons);
      let tmp = selected_addons.map(({id}) => id);
      other_product.addons.forEach(a => {
        if(a.id && tmp.indexOf(a.id) == -1) {
          o.push(a);
        }
      });
      orderItem.addons = o;
    }

    const currentOrderItemAddons = (getters.order_items || {}).addons || [];
    orderItem.addons.forEach(addon => {
      // TODO - Remove this hack added as part of CHKOUTENGG-13846
      const currentAddon = currentOrderItemAddons.find(_addon => _addon.id === addon.id);
      if(currentAddon) {
        addon.added_type = currentAddon.added_type;
      }
      // --- CHKOUTENGG-13846

      addon.type = "addon";
      addon.quantity = addon.quantity || 1;
      if(subData.isMultiDecimal) {
        addon.quantity_in_decimal = MultiDecimalHelper.getQuantity(addon) || MultiDecimalHelper.getDefaultQuantity();
      }
      addon.initialQuantity = MultiDecimalHelper.getInitialQuantity(addon);
    });
    // orderItem.coupon = hostedPage.coupon;
    // orderItem.flattened = [].concat(orderItem.plan, orderItem.addons);
    return orderItem;
  }

  static canShowBreakup(estimate) {
    return estimate && (this.hasPricingModelItem(estimate)
                || (estimate.taxes && estimate.taxes.length > 0)
                || (estimate.discounts && estimate.discounts.length > 0));
  }

  static hasPricingModelItem(estimate) {
    if(estimate.line_items && estimate.line_items.length > 0){
      return estimate.line_items.some(i => {
        switch(i.entity_type) {
          case "plan":
          case "addon":
          return i.pricing_model == "tiered";
        }
      });
    }
  }

  static getCancelReasonText(ref, subData) {
    let reasonValue = subCancelReasonCodeConfig.getLocalizedTextByCode(subData.cancel_reason_code)
    if(subData.isScheduledForCancellation()) {
      return ref.t("subscription.sch_cancel.reason_code.info", {
        reason: reasonValue, sub_cancel_reason_code: reasonValue 
      });
    }
    return ref.t("subscription.canceled.reason_code.info", {
      reason: reasonValue, sub_cancel_reason_code: reasonValue 
    });
  }
}