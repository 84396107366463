// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "./common"
import Vue from 'vue'
import App from '@/App'
import router from '@/router/portal'
import store from "@/store/portal"
import cbClient from "@/utils/cb-client"
import PortalHeader from "@/mixins/portal-header"
import patchRouter from '@/router-monkey-patch'

const BE_ID = "be_id";

window.cbjsLoader.then(() => {
  Chargebee.getInstance().setReferrerModule("portal_v3")
})

if(window.top != window) {
  patchRouter(router);
}

if(window.self == window.top){
  window.addClass(window.document.body, "cb-fullpage");
}
else{
  window.document.body.style.background = "none";
}

/* 
  ===> For MBE Branding <===
  1. Preview portal uses store for maintaining be_id since localstorage is not allowed in Preview.
  2. Other portal flows uses localstorage for maintainig be_id since the be_id needs to be persisted 
  in query_param of all routes even after page reload.
*/
if(window && window.initInfo && window.initInfo.business_entity_id){
  store.dispatch("set_business_entity_id", window.initInfo.business_entity_id); // #1
  window.cbStorage.setItem(BE_ID, window.initInfo.business_entity_id); // #2
}
cbClient.syncWithStore(store)
store.dispatch("init_auth");

Vue.mixin(PortalHeader);

router.customPush = (options) => {
  options.query = options.query || {};
  if(store.state.config.entry_point) {
    options.query["source"] = store.state.config.entry_point;
  }
  addBeIdQueryParam(options);
  router.push(options);
}

router.customReplace = (options) => {
  options.query = options.query || {};
  if(store.state.config.entry_point) {
    options.query["source"] = store.state.config.entry_point;
  }
  addBeIdQueryParam(options);
  router.replace(options);
}

var addBeIdQueryParam = function(options){
  const beId = window.cbStorage.getItem(BE_ID);
  if(beId){
    options.query[BE_ID] = beId;
  }
}

// TODO combine this with checkout
var calculateGA = function(route) {
  if(route.meta.gaKey) {
    if(typeof route.meta.gaKey == "function") {
      return route.meta.gaKey(route);
    }
    else {
      return route.meta.gaKey;
    }
  }
}

var fetchFwtParams = function(params) {
  return Object.keys(params).reduce((prev, next) => {
    if(next.startsWith("fwt")) {
      let p = next.match(/fwt\[(.*)\]/);
      if(p) {
        prev[p[1]] = params[next];
      }
    }
    return prev;
  }, {})
}

var _log = function (to) {
  if (!window.logger) {
    return;
  }
  if (to.meta) {
    Object.keys(to.meta).forEach(k => window.logger.addMetaData(k, to.meta[k]));
  }
  window.logger.i({ "portal_path": to.fullPath || to.path });
  window.logger.logData.send('kvl');
}

router.beforeEach((to, from, next) => {
  _log(to);
  if(to.query.hp_opener == "chargebee") {
    window.isOpenedByCb = true;
  }
  if(to.query.hp_referrer) {
    window.cbReferrer = to.query.hp_referrer;
  }
  document.body.$scrollTop = document.documentElement ? document.documentElement.scrollTop : 0;
  document.documentElement.scrollTop = 0;
  if(to.meta.scopes && to.meta.scopes.length > 0 && store.state.auth.scopes.length > 0) {
    if(!store.state.auth.scopes.some((s) => to.meta.scopes.indexOf(s) > -1)) {
      window.cbStorage.removeItem('cbt');
      // Should rethink this logic, whether to go with 403
      store.dispatch("logout_user");
    }
  }
  next();

  let fwtParams = fetchFwtParams(to.query || {});
  if(Object.keys(fwtParams).length > 0) {
    store.dispatch("set_fwt", fwtParams);
  }
});

router.afterEach((to, from) => {
  let gaKey = calculateGA(to);
  if(gaKey) {
    window.cbPostMessage({key: "cb.page_visited", value: gaKey, type: 'portal'});
  }
});


new Vue({
    router: router,
    store: store,
    render: h => h(App)
}).$mount("#portal");
